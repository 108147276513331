<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card no-body>
            <b-card-header class="border-0">
              <h3 class="mb-0">{{ $t("Search") }}</h3>
            </b-card-header>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Store')">
                      <select
                        class="form-control"
                        v-model="cur_LocationID"
                        @change="store_changed"
                      >
                        <option></option>
                        <option
                          v-for="(loc, idx) in storelist"
                          :key="idx"
                          :value="loc.LocationID"
                        >
                          {{ loc.LocationName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('From Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        :placeholder="$t('From Date') + '...'"
                        v-model="cur_start_dt"
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('To Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        :placeholder="$t('To Date') + '...'"
                        v-model="cur_end_dt"
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Terminal')">
                      <select class="form-control" v-model="cur_ComputerName">
                        <option></option>
                        <option
                          v-for="(box, idx) in boxlist"
                          :key="idx"
                          :value="box"
                        >
                          {{ box }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Staff')">
                      <select class="form-control" v-model="cur_user_IDn">
                        <option></option>
                        <option
                          v-for="(usr, idx) in userlist"
                          :key="idx"
                          :value="usr.IDn"
                        >
                          {{ usr.UserName }}-{{ idx }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Department')">
                      <select
                        class="form-control"
                        v-model="cur_department_IDn"
                        @change="get_category_list"
                      >
                        <option></option>
                        <option
                          v-for="(dpt, idx) in departmentlist"
                          :key="idx"
                          :value="dpt.IDn"
                        >
                          {{ dpt.Name1 }}-{{ dpt.Name2 }}-{{ idx }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Category')">
                      <select class="form-control" v-model="cur_category_IDn">
                        <option></option>
                        <option
                          v-for="(ct, idx) in categorylist"
                          :key="idx"
                          :value="ct.IDn"
                        >
                          {{ ct.Name1 }}-{{ ct.Name2 }}-{{ idx }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Name') + '1'">
                      <b-input
                        class="form-control"
                        :placeholder="$t('Name') + '1'"
                        v-model="cur_name1"
                      />
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Name') + '2'">
                      <b-input
                        class="form-control"
                        :placeholder="$t('Name') + '2'"
                        v-model="cur_name2"
                      />
                    </base-input>
                  </b-col>
                  <b-col> </b-col>
                </b-row>
                <b-row class="justify-content-lg-center">
                  <!--b-col class="text-center" lg="2">
                    <b-button variant="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</b-button>
                  </b-col-->
                  <b-col class="text-center" lg="2">
                    <b-button variant="primary" @click="form_search">{{
                      $t("Search")
                    }}</b-button>
                  </b-col>
                  <!-- <b-col class="text-center" lg="2">
                    <a
                      v-bind:href="export_url"
                      class="btn btn-primary"
                      target="_blank"
                      >{{ $t("Export") }}</a
                    >
                  </b-col> -->
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <b-card>
            <p class="p-3" v-if="listdata">
              <el-table
                stripe
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="listdata"
              >
                <el-table-column label="PLU" prop="PLU" />
                <el-table-column :label="$t('Name')">
                  <template v-slot="{ row }">
                    {{ row.Name1 }} {{ row.Name2 }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Unit Price')">
                  <template v-slot="{ row }">
                    {{ (row.UnitPrice / row.Qty).toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Ave Cost')">
                  <template v-slot="{ row }">
                    {{ (row.SalePrice / row.Qty).toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Quantity')" prop="Qty" />
                <el-table-column :label="$t('Subtotal')" prop="SalePrice" />
                <el-table-column :label="$t('Percentage')">
                  <template v-slot="{ row }">
                    {{ ((row.SalePrice * 100) / row.UnitPrice).toFixed(2) }}%
                  </template>
                </el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-4"> <b-col> </b-col> </b-row
      ><!--  -->
    </b-container>
    <vue-element-loading
      :active="showLoading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    />
  </div>
</template>
<script>
import { Table, TableColumn, Button } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import VueElementLoading from "vue-element-loading";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [flatPicker.name]: flatPicker,
    VueElementLoading,
  },
  data() {
    return {
      pickr_config: {
        dateFormat: "Y-m-d",
        maxDate: "today",
      },
      showLoading: false,
      token: "",
      user_id: 0,
      departmentlist: null,
      categorylist: null,
      userlist: null,
      storelist: null,
      boxlist: null, // [ComputerName]?
      plu_barcode: "PLU",
      listdata: null,

      export_url: "",

      cur_department_IDn: 0,
      cur_category_IDn: 0,
      cur_name1: "",
      cur_name2: "",
      cur_user_IDn: 0,
      cur_LocationID: 0,
      cur_ComputerName: 0,
      cur_start_dt: null,
      cur_end_dt: null,

      current_page: 1,
    };
  },
  // watch: {
  //   current_page: function (newval, oldval) {
  //     this.get_list_data();
  //   },
  // },
  computed: {
    pdfBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    async get_store_data() {
      this.showLoading = true;
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Store/report_search",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showLoading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storelist = rt.data.stores;
              that.departmentlist = rt.data.departments;
              that.categorylist = [];
              that.cur_LocationID = 0;
              that.boxlist = [];
              that.userlist = [];
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showLoading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_list_data() {
      this.showLoading = true;
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var url = "";
      if (axios.defaults.baseURL != undefined) {
        url = axios.defaults.baseURL;
      }
      url += "/Api/Web/Pos_sales_by_product/pdf?";
      url += "token=" + this.token;
      url += "&user_id=" + this.user_id;

      if (this.cur_department_IDn) {
        bodyFormData.append("DepartmentIDn", this.cur_department_IDn);
        url += "&DepartmentIDn=" + this.cur_department_IDn;
      }
      if (this.cur_category_IDn) {
        bodyFormData.append("CategoryIDn", this.cur_category_IDn);
        url += "&CategoryIDn=" + this.cur_category_IDn;
      }
      if (this.cur_name1) {
        bodyFormData.append("Name1", this.cur_name1);
        url += "&Name1=" + this.cur_name1;
      }
      if (this.cur_name2) {
        bodyFormData.append("Name1", this.cur_name2);
        url += "&Name2=" + this.cur_name2;
      }
      if (this.cur_user_IDn) {
        bodyFormData.append("UserIDn", this.cur_user_IDn);
        url += "&UserIDn=" + this.cur_user_IDn;
      }
      if (this.cur_LocationID) {
        bodyFormData.append("LocationID", this.cur_LocationID);
        url += "&LocationID=" + this.cur_LocationID;
      }
      if (this.cur_ComputerName) {
        bodyFormData.append("ComputerName", this.cur_ComputerName);
        url += "&ComputerName=" + this.cur_ComputerName;
      }
      if (this.cur_start_dt) {
        bodyFormData.append("start_dt", this.cur_start_dt);
        url += "&start_dt=" + this.cur_start_dt;
      }
      if (this.cur_end_dt) {
        bodyFormData.append("end_dt", this.cur_end_dt);
        url += "&end_dt=" + this.cur_end_dt;
      }
      this.export_url = url;

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Pos_sales_by_product",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showLoading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.listdata = rt.data.listdata;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showLoading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    store_changed() {
      for (var i = 0; i < this.storelist.length; i++) {
        if (this.cur_LocationID == this.storelist[i]["LocationID"]) {
          this.boxlist = this.storelist[i]["boxlist"];
          this.userlist = this.storelist[i]["userlist"];
        }
      }
    },
    get_category_list() {
      if (!this.cur_department_IDn) {
        this.categorylist = [];
        return;
      }

      for (var i = 0; i < this.departmentlist.length; i++) {
        if (this.cur_department_IDn == this.departmentlist[i]["IDn"]) {
          this.categorylist = this.departmentlist[i]["category"];
        }
      }
    },
    form_cancel() {
      this.$router.push("/store");
    },
    async form_search() {
      this.get_list_data();
    },
    get_today() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
    async init_data() {
      this.cur_start_dt = this.get_today();
      this.cur_end_dt = this.get_today();
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      this.get_store_data();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
